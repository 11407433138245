import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { ApplicationDataService } from '@dpdhl/iot-shared-ui';
import {
  DefaultViewConfiguration,
  GermanUseCases,
  PredictiveMaintenanceUseCases,
  ViewConfigurationModel,
} from './view-configuration.model';
import { IotApplicationModel } from '@dpdhl-iot/api/management';
import { DateTime } from 'luxon';
import { AlertViewModel } from '@dpdhl-iot/shared';
import { TranslateService } from '@ngx-translate/core';
import { SensorType } from '../graph-management/enums/sensor-type.enum';
import { SensorByPrioritySorter } from '../../global-functions/graph-function';

@Injectable({
  providedIn: 'root',
})
export class ViewConfigurationService {
  private readonly viewConfigurationSubject = new BehaviorSubject<ViewConfigurationModel>(
    DefaultViewConfiguration,
  );
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public currentViewConfiguration$ = this.viewConfigurationSubject.asObservable();

  constructor(
    private readonly applicationDataService: ApplicationDataService,
    private readonly translateService: TranslateService,
  ) {
    this.applicationDataService.application$
      .pipe(distinctUntilChanged((prev, curr) => prev?.application?.id === curr?.application?.id))
      .subscribe((app) => {
        if (app?.application) {
          this.updateViewConfiguration(app.application);
        }
      });
  }

  updateViewConfiguration(app: IotApplicationModel): ViewConfigurationModel {
    const viewConfigurationModel: ViewConfigurationModel = new ViewConfigurationModel(app);

    const dscUkiSensorPriority = [SensorType.Temperature, SensorType.Humidity, SensorType.CO2];

    viewConfigurationModel.navigationMenu = this.createMenuItems();

    switch (app.id) {
      case 'dsc-wrap-robots':
        viewConfigurationModel.facilityView.show_dscWrappingRobots = true;
        viewConfigurationModel.graph.show_dscWrappingRobots = true;
        break;
      case 'pnp-battery-como':
        viewConfigurationModel.graph.show_pnpBatteryComo = true;
        break;
      case 'csi-inventory-overview':
      case 'exp-bigbelt':
      case 'exp-inventory-tracking':
      case 'exp-lrt':
      case 'dsc-ble-uk':
      case 'dsc-sky-ble':
      case 'pnp-inv-scan-demo':
        viewConfigurationModel.alert.showColumn_Device = false;
        viewConfigurationModel.inventoryTracking = true;
        break;
      case 'dsc-uki-ev-battery':
        viewConfigurationModel.graph.sensorSorter = SensorByPrioritySorter(dscUkiSensorPriority);
        break;
      case 'csi-indoor-airquality':
        viewConfigurationModel.deviceList.includeDeviceLink = true;
        break;
      case 'ecs-spain-como':
        viewConfigurationModel.notification.showDeviceNotification = true;
        break;
      default:
        if (PredictiveMaintenanceUseCases.includes(app.id)) {
          viewConfigurationModel.alert.removeValuePrefixes = true;
          viewConfigurationModel.alert.showColumn_Country = false;
          viewConfigurationModel.alert.showColumn_Location = true;
          viewConfigurationModel.alert.showColumn_Threshold = false;
          viewConfigurationModel.alert.showColumn_Value = true;
          viewConfigurationModel.alert.showColumn_FacilityName = false;
          viewConfigurationModel.alert.showColumn_LastComment = true;
          viewConfigurationModel.alert.showExport = true;
          viewConfigurationModel.alert.showNoFindings = true;
          viewConfigurationModel.alert.toRowEntry = this.predMainToRowEntry;
          viewConfigurationModel.areaView.show_predictiveMaintenance = true;
          viewConfigurationModel.facilityView.show_predictiveMaintenance = true;
          viewConfigurationModel.areaView.localizations.area =
            'predictiveMaintenance.common.warehouse';
          viewConfigurationModel.facilityView.localizations.facility =
            'predictiveMaintenance.common.facility';
          viewConfigurationModel.areaView.localizations.selectArea =
            'predictiveMaintenance.common.selectArea';
          viewConfigurationModel.facilityView.localizations.selectFacility =
            'predictiveMaintenance.common.selectFacility';
          viewConfigurationModel.navigationMenu = viewConfigurationModel.navigationMenu.filter(
            (menu) =>
              !['facilities', 'alert-rule-groups', 'device-management'].includes(
                menu.path!.toString(),
              ),
          );
          viewConfigurationModel.notification.showSensorNotificationHint = true;
          viewConfigurationModel.predictiveMaintenance = true;
        }
        break;
    }
    viewConfigurationModel.language = GermanUseCases.includes(app.id) ? 'de' : 'en';

    this.viewConfigurationSubject.next(viewConfigurationModel);
    return viewConfigurationModel;
  }

  predMainToRowEntry(data: AlertViewModel): { [key: string]: string | number | Date } {
    return {
      'Geräte-ID': data.deviceId ?? '',
      Sorter: data.location ?? '',
      Zeitpunkt: DateTime.fromMillis(data.deviceTimestamp).toFormat('dd.MM.yyyy HH:mm'),
      Alarmtyp: data.alertType,
      Schwere: data.severity ?? '',
      Wert: data.threshold,
      Status: data.statusId,
      'Letzer Kommentar': data.alertRemarks ? data.alertRemarks[0]?.remarks : '',
    };
  }

  private createMenuItems() {
    return [
      {
        label: this.translateService.instant('menu.dashboard'),
        helpSection: 'dashboard',
        path: '',
      },
      {
        label: this.translateService.instant('menu.facility'),
        path: 'facilities',
        helpSection: 'facility',
      },
      {
        label: this.translateService.instant('menu.alert'),
        helpSection: 'alerts',
        path: 'alerts',
      },
      {
        label: this.translateService.instant('menu.alertTemplates'),
        path: 'alert-rule-groups',
        helpSection: 'alert-templates',
      },
      {
        label: this.translateService.instant('menu.notifications'),
        helpSection: 'notifications',
        path: 'notifications',
      },
      {
        label: this.translateService.instant('menu.deviceManagement'),
        path: 'device-management',
        helpSection: 'device-management',
      },
    ];
  }
}
